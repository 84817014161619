<template>
  <div class="h-screen flex flex-col relative bg-gray-200 md:bg-white">
    <div class="flex-1 overflow-hidden">
      <router-view></router-view>
    </div>
    <div class="tabbar hairline-top h-12 flex items-center z-40 bg-white shadow-lg">
      <router-link 
        class="flex flex-col items-center flex-1" 
        :to="`/tabs/${item.url}`" 
        v-for="(item,index) in tabs" 
        :key="index"
      >
        <i 
        class="iconfont text-3xl" 
        :class="[ 
          $route.path == `/tabs/${item.url}` ? item.iconActive : item.icon, 
          { 'text-primary': $route.path == `/tabs/${item.url}` }
        ]"
        ></i>
        <div class="text-xs transform scale-85" :class="{ 'text-primary': $route.path == `/tabs/${item.url}` }">{{item.label}}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      tabs: [
        {
          icon: 'icon-home',
          iconActive: 'icon-home-fill',
          label: '首页',
          url: 'home',
        },
        {
          icon: 'icon-all',
          iconActive: 'icon-all-fill',
          label: '分类',
          url: 'category',
        },
        {
          icon: 'icon-cart-Empty',
          iconActive: 'icon-cart-Empty-fill',
          label: '购物车',
          url: 'cart',
        },
        {
          icon: 'icon-account',
          iconActive: 'icon-account-fill',
          label: '我的',
          url: 'person',
        },
      ]
    }
  },
  computed: {
    
  },
  methods:{},
  created(){
  }
}
</script>

<style lang='stylus'>

</style>